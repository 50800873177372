/**
 * Application colors schema
 */

$app-color-primary: #1A02A1 !default;
$app-color-tone: #120070 !default;
$app-color-primary-light-1: lighten($app-color-primary, 40%) !default;
$app-color-primary-light-3: lighten($app-color-primary, 80%) !default;

$app-color-white: #fff !default;
$app-color-black: #1f2020 !default;
$app-color-gray-dark: #737373 !default;

$app-color-warning: #ff8e00 !default;
$app-color-danger: #ff5003 !default;

$app-background-gradient: linear-gradient(90deg, #10006D 0.41%, #3F00F6 129.85%) !default;

/**
 * Application fonts
 */

$app-font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$app-font-family-sans-serif-bold: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$app-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$app-font-family-base: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;