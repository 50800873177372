/**
 * Kyndryl
 */

$app-color-primary-dark: darken(#fb512f, 5%);
$app-color-primary: #fb512f;
$app-color-tone: #fb512f;
$app-color-primary-light-1: #fb512f;
$app-color-primary-light-3: #eeeeee;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #77706f;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, #fff 0 , #fff 100%);