$products-product-cart-btn-cart__disabled_color: $app-color-white;
$products-product-cart-btn-cart-disabled__disabled_color: $app-color-white;

[data-skin="kyndryl"] .btn-cart {
  &[disabled] {
    opacity: 0.8;
    @include hover-focus-active() {
      color: $app-color-primary !important;
      border: solid 1px $app-color-primary;
    }
  }

  &.btn-cart-disabled {
    opacity: 0.8;
    @include hover-focus-active() {
      color: $app-color-primary !important;
      border: solid 1px $app-color-primary;
    }
  }
}